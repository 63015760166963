import './App.css';
import Navbar from '../src/component/Navbar';
import Homepage from '../src/component/Homepage';
import Footer from '../src/component/Footer';


function App() {
  return (
    <div className="App">
      <Navbar/>
      <Homepage/>
      <Footer/>
    </div>
  );
}

export default App;
