import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from "../assets/images/logo.png";
import socialicon1 from "../assets/images/social-icon1.png";
import socialicon2 from "../assets/images/social-icon2.png";
import socialicon3 from "../assets/images/social-icon3.png";
import socialicon4 from "../assets/images/social-icon4.png";


function Footer() {
  return (

    <footer className="footer-section">
     <div className="container">
     <div className="row footer-bg">
      {/* <div className="col mb-3">
        <a href="/" className="d-flex align-items-center mb-3 link-dark text-decoration-none"> 
        <img src={logo} alt="logo"/>
        </a>
        <p>Design amazing digital experiences
        that create happy in the world</p>
      </div>
  
      <div className="col mb-3">
      <h5>Product</h5>
        <ul className="nav flex-column">
          <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Home</a></li>
          <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Features</a></li>
          <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Pricing</a></li>
          <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">FAQs</a></li>
          <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">About</a></li>
        </ul>
      </div>
  
      <div className="col mb-3">
      <h5>Company</h5>
        <ul className="nav flex-column">
          <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Home</a></li>
          <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Features</a></li>
          <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Pricing</a></li>
          <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">FAQs</a></li>
          <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">About</a></li>
        </ul>
      </div>
  
      <div className="col mb-3">
        <h5>Resources</h5>
        <ul className="nav flex-column">
          <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Home</a></li>
          <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Features</a></li>
          <li claclassNamess="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Pricing</a></li>
          <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">FAQs</a></li>
          <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">About</a></li>
        </ul>
      </div>
  
      <div className="col mb-3">
        <h5>Social</h5>
        <ul className="nav flex-column">
          <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Home</a></li>
          <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Features</a></li>
          <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Pricing</a></li>
          <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">FAQs</a></li>
          <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">About</a></li>
        </ul>
      </div>
      <div className="col mb-3">
        <h5>Legal</h5>
        <ul className="nav flex-column">
          <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Home</a></li>
          <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Features</a></li>
          <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Pricing</a></li>
          <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">FAQs</a></li>
          <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">About</a></li>
        </ul>
      </div> */}

      <div className="row mt-4">
      <div className="col mb-3">
        <p>2024 AIRebooker. All rights reserved.</p>
        </div>
        <div className="col mb-3">
        <ul className="social-links">
        <li className="nav-item mb-2">
            <a href="#" className="nav-link p-0 text-muted"><img src={socialicon1} alt="arrow" className="social-icons" /></a>
            </li>
            <li className="nav-item mb-2">
            <a href="#" className="nav-link p-0 text-muted"><img src={socialicon2} alt="arrow" className="social-icons" /></a>
            </li>
            <li className="nav-item mb-2">
            <a href="#" className="nav-link p-0 text-muted"><img src={socialicon3} alt="arrow" className="social-icons" /></a>
            </li>
            <li className="nav-item mb-2">
            <a href="#" className="nav-link p-0 text-muted"><img src={socialicon4} alt="arrow" className="social-icons" /></a>
            </li>
            </ul>
        </div>
        </div>
      </div>

      

      </div>
    </footer>
  );
}

export default Footer;