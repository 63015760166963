import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from "../assets/images/logo.png";

function NavBar() {
  return (
    <Navbar expand="lg" className="nav-bar">
      <Container>
        <Navbar.Brand href="/">
            <img src={logo} alt="logo"/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        {/* <Navbar.Collapse id="navbarScroll">
          <Nav
            className="my-2 my-lg-0"
            style={{ justifyContent: 'center' , width: '100%' }}
          >
            <Nav.Link href="/Reviews">Reviews</Nav.Link>
            <Nav.Link href="/Reviews">People</Nav.Link>
            <Nav.Link href="/Partners">Partners</Nav.Link>
            <Nav.Link href="/Pricing">Pricing</Nav.Link>
          </Nav>
          
        </Navbar.Collapse> */}
        <Button variant="outline-success" className='get_app_btn'>Get The App Soon</Button>
      </Container>
    </Navbar>
  );
}

export default NavBar;